/*循环淡入淡出*/
@keyframes backgroundAnimation {
  0% {
    opacity: 0.4;
    background-image: url('/img/game-back-image01.jpeg');
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.4;
    background-image: url('/img/game-back-image02.webp');
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
    background-image: url('/img/game-back-image03.webp');
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 0.4;
    background-image: url('/img/game-back-image04.webp');
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
    background-image: url('/img/game-back-image05.webp');
  }
}

/*左右循环*/
@keyframes backgroundAnimation2 {
  0% {
    opacity: 0;
    background-position: 100% 0;
  }
  25% {
    opacity: 1;
    background-position: 0 0;
  }
  50% {
    opacity: 1;
    background-position: 10% 0;
  }
  75% {
    opacity: 1;
    background-position: 0 0;
  }
  100% {
    opacity: 0;
    background-position: 100% 0;
  }
}

@keyframes backgroundAnimation3 {
  0% {
    background-position: 100% 0;
    background-image: url('/img/game-back-image01.jpeg');
  }
  25% {
    background-position: 0 0;
    background-image: url('/img/game-back-image02.webp');
  }
  50% {
    background-position: 10% 0;
    background-image: url('/img/game-back-image03.webp');
  }
  75% {
    background-position: 0 0;
    background-image: url('/img/game-back-image04.webp');
  }
  100% {
    background-position: 100% 0;
    background-image: url('/img/game-back-image05.webp');
  }
}

@font-face {
  font-family: 'Bungee Spice';
  src: url('/font/BungeeSpice-Regular.ttf') format('truetype'),
       url("/font/Nabla-Regular-VariableFont_EDPT,EHLT.ttf") format('truetype');
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.lg-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 0, 100% 0, 100% 0; /* 初始位置，从右向左 */
  animation: backgroundAnimation3 30s ease-in-out infinite; /* 10秒循环一次 */
}

#game-container {
  display: none;
  margin-top: 20px;
  position: relative;
  background-image: linear-gradient(rgba(255, 0, 0, 0.1) 1px, transparent 1px),
  linear-gradient(90deg, rgba(255, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 100px 100px;
  float: left;
  border-radius: 20px;
}


/* 初始状态下隐藏左右两个 div */
#left,
#right {
  display: none;
}

/* 在屏幕宽度大于等于 500px 时显示左右两个 div */
@media only screen and (min-width: 500px) {
  #left {
    width: 30%;
    height: 100%;
    margin-right: 0px;
    margin-top: 0px;
    float: left;
    display: block;
  }

  #right {
    height: 100%;
    margin-right: 0px;
    margin-top: 0px;
    position: relative;
    float: left;
    display: block;
  }
}

.eye-protection-theme {
  /*background-color: #6bff9d;*/
  background-color: #39c969;
}

.person {
  position: absolute;
  width: 100px;
  height: 100px;
  background-image: url("/img/person02.gif");
  background-size: cover;
  background-position: center;
}

.chinese-character {
  position: absolute;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  font-family: 'Bungee Spice', cursive;
  font-size: 32px; /* 适当调整字体大小 */
}

#game-table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

#game-table td {
  width: 10%;
  height: 10%;
  position: relative;
}

#game-table td:hover {
  background-color: rgba(255, 255, 255, 0.4);
}


#character-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /*background-color: #87CEEB;*/
  background-color: #6b83ff;
}

#cartoon-character {
  width: 100px;
  height: 150px;
  background-color: #FFD700;
  border-radius: 50% 50% 40% 40%;
}

#key-board {
  display: none;
  width: 200px;
  height: 200px;
  margin-top: 5px;
  position: absolute;
  bottom: 0px;
  /*background-color: rgba(255, 0, 0, 0.5);*/

  /*display: grid;*/
  grid-template-columns: repeat(2, 1fr); /* 2 列，每列宽度平均分布 */
  gap: 10px; /* 设置按钮之间的间距 */
  justify-content: center; /* 水平方向居中对齐 */
  align-items: center; /* 垂直方向居中对齐 */
}

/*.arrow-button {*/
/*  width: 50px;*/
/*  height: 50px;*/
/*  margin: 5px;*/
/*  cursor: pointer;*/
/*  background-color: #ccc;*/
/*  border: 1px solid #999;*/
/*  border-radius: 5px;*/
/*  text-align: center;*/
/*  line-height: 50px;*/
/*}*/

.arrow-button {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  border: 1px solid #ccc;
  margin: 5px;
  border-radius: 30%; /* 设置圆形 */
  background-color: rgba(255, 255, 255, 0.7); /* 设置半透明白色背景 */
}

#start-container {
  height: 300px;
  width: 300px;
  /*background-color: #f0f0f0;*/
  margin-top: 20px;
  position: relative;
  float: left;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#moving-image {
  position: absolute;
  width: 50px; /* 设置图片的宽度 */
  height: 50px; /* 设置图片的高度 */
  border-radius: 50%; /* 设置图片为圆形，可以根据实际需求调整 */
  animation: moveAround 8s linear infinite; /* 设置动画效果，8秒循环一次，linear表示匀速运动 */
}

@keyframes moveAround {
  0%, 100% {
    top: 0;
    left: 0;
  }
  25% {
    top: 0;
    left: calc(100% - 50px); /* 从右侧移动到左侧 */
  }
  50% {
    top: calc(100% - 50px); /* 从上侧移动到下侧 */
    left: calc(100% - 50px);
  }
  75% {
    top: calc(100% - 50px);
    left: 0; /* 从下侧移动到上侧 */
  }
}

.button-character {
  color: #FFD700;
  text-align: center;
  line-height: 50px;
  font-family: 'Bungee Spice', cursive;
  font-size: 25px; /* 适当调整字体大小 */
  border-radius: 5%; /* 设置圆形 */
  background-color: rgb(69, 213, 180); /* 设置半透明白色背景 */
}
